// This file contains customised Architecture settings.
// Refer to bower_components/Architecture-Blueprint/markup/js/architecture.js for full list of options

var projectSettings = {
	inlineSVG: [
		'.footer__social img',
		'.off-canvas__logo img',
		'.room-details__icons-item img',
		'.multiple-icons img'
	],
	slider: {
		speed: 6500,
		animationSpeed: 1500,
		arrows: true,
		dots: true,
	},
	mapStyle: [{featureType:"administrative.province",elementType:"all",stylers:[{visibility:"off"}]},{featureType:"landscape",elementType:"all",stylers:[{saturation:-100},{lightness:60}]},{featureType:"poi",elementType:"labels",stylers:[{visibility:"off"}]},{featureType:"poi",elementType:"labels.text",stylers:[{visibility:"on"}]},{featureType:"poi.park",elementType:"geometry.fill",stylers:[{color:"#e8efbb"},{lightness:40},{saturation:-40}]},{featureType:"poi.park",elementType:"labels.text.fill",stylers:[{color:"#a7b793"}]},{featureType:"road.highway",elementType:"geometry.fill",stylers:[{color:"#d9d9d9"},{lightness:40}]},{featureType:"road.highway",elementType:"geometry.stroke",stylers:[{visibility:"off"}]},{featureType:"road.highway",elementType:"labels.text",stylers:[{visibility:"on"}]},{featureType:"road.highway",elementType:"labels.text.fill",stylers:[{color:"#ffffff"}]},{featureType:"road.highway",elementType:"labels.text.stroke",stylers:[{color:"#acacac"},{visibility:"off"}]},{featureType:"road.highway",elementType:"labels.icon",stylers:[{color:"#cac9c9"},{visibility:"off"}]},{featureType:"road.local",elementType:"all",stylers:[{saturation:-100},{lightness:40},{visibility:"on"}]},{featureType:"transit",elementType:"all",stylers:[{saturation:-100},{visibility:"simplified"}]},{featureType:"water",elementType:"all",stylers:[{visibility:"on"},{lightness:30},{color:"#cfeaef"}]}],
	accordion: {
		showFirstItem: true
	},
	sliderFullHeightScrollButton: false,
	appendDotsToText: [
		'.intro h1',
		'.intro .room-details__icons h2',
		'.home h2',
		'.landing__item h2 a',
		'.accommodation__item h2 a',
		'.concierge-detail__sidebar-title h3',
		'.concierge-detail__sidebar-item-info h4 a',
		'.concierge-landing h4 a',
		'.detail__rate h3',
		'.detail__features h2',
		'.other-rooms h2',
		'.other-rooms h3',
		'.menus h2',
		'.booking__gifts',
		'.popup__text h2',
		'.off-canvas__main a'
	],
	applyTypingAnimation: [
		'.intro h1',
		'.intro .intro__content p',
		'.home h2',
		'.home h3',
		'.landing__item h2 a',
		'.landing__item h3 a',
		'.accommodation__item h2 a',
		'.accommodation__item h3 a',
		'.concierge-detail__sidebar-title h3',
		'.concierge-detail__sidebar-item-info h4 a',
		'.concierge-landing h4 a',
		'.concierge-landing h5 a',
		'.detail__rate h3',
		'.detail__rate p',
		'.detail__features h2',
		'.other-rooms h2',
		'.other-rooms h3',
		'.menus h2',
		'.standard-content h2',
		'.standard-content h3',
		'.offers__item-content-info h2',
		'.offers__item-content-info h3',
		'.terms__heading h2'
	]
};


if ( $('body').hasClass('theme-staverton') ) {
	projectSettings.mapStyle = [{"featureType":"road.highway","elementType":"labels.text","stylers":[{"visibility":"on"}]},{"featureType":"road.highway","elementType":"labels.text.fill","stylers":[{"color":"#ffffff"}]},{"featureType":"road.highway","elementType":"labels.text.stroke","stylers":[{"color":"#acacac"},{"visibility":"off"}]},{"featureType":"road.highway","elementType":"labels.icon","stylers":[{"color":"#cac9c9"},{"visibility":"off"}]},{"featureType":"road.local","elementType":"all","stylers":[{"saturation":-100},{"lightness":40},{"visibility":"on"}]},{"featureType":"transit","elementType":"all","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"water","elementType":"all","stylers":[{"visibility":"on"},{"lightness":30},{"color":"#cfeaef"}]}];
}
